$.extend(true, window.locales.en, {
	management: {
		goToProjectButton: 'false'
	},
	projects: {
		
	},
	organizations: {
		name: 'Customer Name',
		newOrganization: 'Create new customer',
		organization: 'Customer',
		organizationChoice: 'Choose your customer'
	},
	tabs: {
		activations: 'Activations',
		activationCreate: 'Create Activation',
		activationEdit: 'Edit Activation',
		applications: 'Desktop Applications',
		applicationCreate: 'Create Application',
		applicationEdit: 'Edit Application',
		installers: 'Installers',
		installerCreate: 'Create Installer',
		stations: 'Stations',
		stationEdit: 'Edit Station'
	}
});