import Polyglot from 'node-polyglot';
import Vue from 'vue';

// For $.extend
import $ from 'jquery';
if(!window.$) {
	window.$ = window.jQuery = $;
}

window.locales = {};
require('../../locales/en');
require('../../locales/en-CA');
require('../../locales/es');
require('../../../vue/locales');
let language = window.navigator.userLanguage || window.navigator.language;

let locale = {};
if(window.locales[language]) {
	window.$.extend(true, locale, window.locales[language]);
}
// If we can't find en-US, use en if defined
else if(window.locales[language.split('-')[0]]) {
	window.$.extend(true, locale, window.locales[language.split('-')[0]]);
}

// Fill in any gaps in locale
locale = window.$.extend(true, {}, window.locales.en, locale);

if(window.location.search?.includes('debugLocalization')) {
	locale = crapNullClone(locale);
}

window.i18n = new Polyglot({
	phrases: locale
});

Vue.filter('i18n', function(value) {
	return window.i18n.t(value);
});

Vue.mixin({
	methods: {
		i18n: (value, options) => window.i18n.t(value, options)
	}
});

function crapNullClone(obj) {
	var clone = {};
	for(var id in obj) {
		if(typeof obj[id] == 'object' && obj[id].constructor == Object) {
			clone[id] = crapNullClone(obj[id]);
		} else {
			clone[id] = 'Buenos días';
		}
	}

	return clone;
}