import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Vue from 'vue';

if(process.env.VUE_APP_BUGSNAG_JS_API_KEY) {
	let bugsnagEnabled = true;
	Bugsnag.start({
		apiKey: process.env.VUE_APP_BUGSNAG_JS_API_KEY,
		appVersion: process.env.VUE_APP_HEROKU_RELEASE_VERSION,
		appType: 'client' + ' ' + process.env.VUE_APP_AWS_REGION,
		enabledReleaseStages: ['production', 'staging'/*, 'development'*/],
		releaseStage: process.env.VUE_APP_RELEASE_STAGE,
		plugins: [new BugsnagPluginVue(Vue)],
		onError: function(event) {
			if(!bugsnagEnabled ||
				// User saved to desktop
				event.request?.url?.includes(':/Users/') || event.request?.url?.includes('file://') ||
				// Service worker failed to update - usually network issues on their end
				event.errors[0]?.errorMessage?.includes('Failed to update a ServiceWorker') || event.errors[0]?.errorMessage?.includes('service-worker.js load failed') || event.errors[0]?.errorMessage?.includes('newestWorker is null') || event.errors[0]?.errorMessage?.includes('ServiceWorker script at ') || event.errors[0]?.errorMessage?.includes('ServiceWorker for scope ') || event.errors[0]?.errorMessage?.includes('service worker registration') || event.errors[0]?.errorMessage?.includes('The Internet connection appears to be offline') ||  event.errors[0]?.errorMessage?.includes('Service Worker script') ||
				// Random issues where users can't download script but retrying works
				event.errors[0]?.errorMessage?.includes('Unexpected end of script') || event.errors[0]?.errorMessage?.includes('Unexpected token \'<\'') ||
				// Customer is having internet issues
				event.originalError?.message === 'Network Error' || event.originalError?.message?.includes('timeout of') || event.originalError?.message === 'Unexpected end of input' || event.originalError?.message === 'ReferenceError: $ is not defined' ||
				// New Relic script keeps spamming this and I can't figure out how else to ignore it since each instance opens a new Bugsnag
				event.errors[0]?.errorMessage?.includes('readonly property') || event.errors[0]?.errorMessage?.includes('has only a getter') ||
				// No idea what these are from but it isn't from our site
				event.errors[0]?.errorMessage?.includes('Identifier \'lsijVersion\'')) {
				return false;
			}

			// NOTE: We can directly import $ once we migrate jquery over to webpack import
			if(window.$?.globalBugsnagInfo) {
				for(let name in window.$.globalBugsnagInfo) {
					event.addMetadata(name, window.$.globalBugsnagInfo[name]);
				}
			}
			if(window.$?.CurrentUser) {
				let user = window.$.extend(true, {}, window.$.CurrentUser);
				delete user.title;
				user.roleName = window.$.UserRole;
				user.rank = window.$.UserRank;
				user.orgName = window.$.OrgName;
				user.rootOrgName = window.$.RootOrgName;
				event.addMetadata('user', user);
			}
			if(window.$?.userPermissions) {
				event.addMetadata('permissions', window.$.userPermissions);
			}

			if(event.errors.length && event.errors[0].errorClass == 'TypeError' && event.errors[0].errorMessage && !event.groupingHash) {
				event.groupingHash = event.errors[0].errorMessage;
			}
		}
	});

	window.bugsnagClient = window.Bugsnag = Bugsnag;
	window.addEventListener('beforeunload', function() {
		bugsnagEnabled = false;
	});
	if(window.$?.setupBugsnagAjaxHandler) {
		window.$.setupBugsnagAjaxHandler();
	}
} else if(process.env.NODE_ENV === 'production') {
	// eslint-disable-next-line
	console.warn('Bugsnag not initialized');
}

// NOTE: Once we directly import $ don't need to wrap this anymore
document.addEventListener('DOMContentLoaded', () => {
	if(window.$) {
		window.$.globalBugsnagInfo = {};
	}

	if(window.$) {
		let currentUser = null;
		Object.defineProperties(window.$, {
			'CurrentUser': {
				get: function() { 
					return currentUser;
				},
				set: function(value) {
					currentUser = value;
					if(window.LogRocket) {
						window.LogRocket.identify($.CurrentUser.plicUserId, {
							...$.CurrentUser,
							email: $.CurrentUser.userName
						});
					}
				},
				configurable: true
			}
		});
	}
});

if(process.env.VUE_APP_LOG_ROCKET_INIT_KEY) {
	// Use a dynamic require to stop LogRocket from initializing script even when not used
	const LogRocket = require('logrocket');
	window.LogRocket = LogRocket;
	LogRocket.init(process.env.VUE_APP_LOG_ROCKET_INIT_KEY, {
		release: process.env.VUE_APP_HEROKU_RELEASE_VERSION,
		network: {
			requestSanitizer: function(request) {
				if(request.url.toLowerCase().indexOf('login') !== -1) {
					request.body = null;
				}
				
				return request;
			}
		}
	});

	LogRocket.getSessionURL(function(sessionURL) {
		if(window.Intercom) {
			let obj = {};
			obj[process.env.VUE_APP_PLIC_APP_SLUG + '_session_url'] = sessionURL;

			window.Intercom('update', obj);
		}
		if(window.Bugsnag) {
			window.Bugsnag.addMetadata('logRocketSessionUrl', sessionURL);
		}
	});
}

if(process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
	// Legacy Universal Analytics
	if(process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID.includes('UA-')) {
		(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

			window.ga('create', process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID, 'auto');
			window.ga('send', 'pageview');
	}
	// Google Analytics 4
	else {
		const script = document.createElement('script');
		script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID);
		script.async = true;
		document.head.appendChild(script);

		window.dataLayer = window.dataLayer || [];
		gtag('js', new Date());
		gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID);
	}
}

function gtag(){window.dataLayer.push(arguments);}