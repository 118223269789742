window.locales.en = {
	composer: {
		mySettings: 'My Settings',
		tools: {
			alignLeft: 'Align Left',
			alignCenter: 'Align Center',
			alignRight: 'Align Right',
			alignJustify: 'Justify',
			alignHorizontalCenter: 'Align horizontal center',
			alignVerticalCenter: 'Align vertical center',
			backgroundColor: 'Background color',
			fontColor: 'Font color'
		},
		undo: {
			redo: 'Redo action',
			redid: 'Reapplied previous action',
			undo: 'Undo action',
			undid: 'Unapplied previous action'
		},
		zoom: {
			buttonTooltip: 'Click to open zoom options',
			highResPreview: 'View high resolution page preview',
			zoom: 'Zoom',
			zoomIn: 'Zoom In',
			zoomOut: 'Zoom Out',
			zoomLevel: 'Zoom: %{level}%'
		}
	},
	login: {
		enterEmailReset: 'Please enter an email to reset the password for',
		forgotPassword: 'Forgot password',
		invalidLogin: 'Invalid email or password',
		logMeIn: 'Log me in',
		loggingIn: 'Logging in',
		password: 'Password',
		pleaseEnterMessage: 'Please enter your email and password',
		privacy: 'Privacy & cookies',
		rememberMe: 'Remember me',
		resetPasswordFailed: 'Error resetting password',
		resetPasswordSent: 'Reset instructions sent. Check your email.',
		signIn: 'SIGN IN',
		username: 'Username'
	},
	management: {
		bookFormats: {
			applyToExistingProjects: 'Apply to existing projects',
			bleed: 'Bleed',
			bottomBleed: 'Bottom Bleed',
			bottomCoverBleed: 'Bottom Cover Bleed',
			bottomCoverSafeSpace: 'Bottom Cover Safe Space',
			bottomSafeSpace: 'Bottom Safe Space',
			bottomWhiteSpace: 'Bottom White Space',
			coverDimensions: 'Cover Dimensions',
			createBookFormat: 'Create Book Format',
			cropDimensions: 'Crop Dimensions',
			cropWidth: 'Crop Width',
			cropHeight: 'Crop Height',
			formatName: 'Name',
			gutterBleed: 'Gutter Bleed',
			gutterBleedInPrint: 'Gutter Bleed In Print',
			gutterCoverSafeSpace: 'Gutter Cover Safe Space',
			gutterSafeSpace: 'Gutter Safe Space',
			gutterWhiteSpace: 'Gutter White Space',
			insideBookDimensions: 'Inside Book Dimensions',
			outsideBleed: 'Outside Bleed',
			outsideCoverBleed: 'Outside Cover Bleed',
			outsideCoverSafeSpace: 'Outside Cover Safe Space',
			outsideSafeSpace: 'Outside Safe Space',
			outsideWhiteSpace: 'Outside White Space',
			requireMultiplesOfFour: 'Require pages in multiples of four',
			topBleed: 'Top Bleed',
			topCoverBleed: 'Top Cover Bleed',
			topCoverSafeSpace: 'Top Cover Safe Space',
			topSafeSpace: 'Top Safe Space',
			topWhiteSpace: 'Top White Space',
			spine: 'Spine',
			spineFormula: 'Spine Formula',
			whiteSpace: 'White Space',
			whiteSpaceTooltip: 'Whitespace is not shown in the Composer, but extra whitespace is included in rendered PDF. ' +
				'This is useful if you cut off a large portion of the output render and still want bordered backgrounds to show in the cropped Book.'
		},
		subjectManagement: {
			editPrimaryPose: 'Edit Primary Pose'
		},
		users: {
			userRoleFilterTooltip: 'If checked %{role} users will be shown'
		},
		goToProjectButton: 'Go to School',
		seasonHeader: 'Season:'
	},
	misc: {
		actions: 'Actions',
		back: 'Back',
		cancel: 'Cancel',
		center: 'Center',
		close: 'Close',
		color: 'Color',
		copyToClipboard: 'Copy to clipboard',
		confirmAction: 'I understand and wish to continue',
		confirmDelete: 'I understand and wish to delete this',
		date: 'Date',
		delete: 'Delete',
		deadline: 'Deadline',
		disabled: 'Disabled',
		download: 'Download',
		edit: 'Edit',
		editName: 'Edit "%{name}"',
		enabled: 'Enabled',
		error: 'Error',
		errorLoading: 'Failed to load.  Please refresh and try again.',
		false: 'No',
		filename: 'Filename',
		filesize: 'Filesize',
		height: 'Height',
		help: 'Help',
		information: 'Information',
		lastModifiedAt: 'Last modified at ',
		loading: 'Loading',
		name: 'Name',
		noChangesToSave: 'No changes to save',
		ok: 'OK',
		permanentAction: 'This action cannot be undone.',
		save: 'Save',
		selected: '%{count} selected',
		selectedOf: '%{count} selected (of %{total})',
		selectAll: 'Select All',
		status: 'Status',
		subjects: 'Subjects',
		true: 'Yes',
		uploadedAt: 'Uploaded At',
		uploadedBy: 'Uploaded By',
		width: 'Width'
	},
	photoPicker: {
		createAlbumButton: 'Create album',
		deleteAlbumButton: 'Delete album',
		denyCandid: 'Deny candid',
		descriptionTags: 'Description Tags',
		editAlbumButton: 'Edit album details',
		editTags: 'Edit tags',
		imageInfoTitle: 'Image Info & Tags',
		moveToAlbumButton: 'Move photos',
		noAlbumsWarning: 'There are no albums to view.  Please add one.',
		noPhotosWarning: 'There are no %{photoType} to view.  Please upload some.',
		photosCount: '%{count} photos',
		removeImage: 'Remove image',
		rotateLeft: 'Rotate left',
		rotateRight: 'Rotate right',
		setAlbumTagsButton: 'Edit album tags',
		setDefaultAlbumButton: 'Set as default',
		subFolder: 'Folder Name',
		subFolderTooltip: 'By default albums are not in a folder.  If you change this from None, this album will show up in a folder in the dropdown.',
		timesUsed: '%{count} times used in Books',
		timesUsedAlt: 'On page %{pages} |||| On pages %{pages}',
		toggleNotInUseLabel: 'Hide Used',
		toggleNotInUseLabelWithCount: 'Hide %{count} Used',
		toggleNotInUseTooltip: 'Hide images which have already been placed in the Book',
		uploadToAlbumButton: 'Upload to album',
		downloadAlbumButton: 'Download album',
		viewLargeImage: 'View large image'
	},
	projects: {
		name: 'Project Name',
		project: 'Project',
		projectInformation: 'Basic project information',
		projects: 'Projects',
		projectSettings: 'Project Settings',
		projectSettingsDescription: 'Advanced project settings'
	},
	organizations: {
		name: 'Organization Name',
		newOrganization: 'Create new organization',
		organization: 'Organization',
		organizationChoice: 'Choose your organization'
	},
	status: {
		'In Work': 'In Work',
		'Not Opened': 'Not Opened',
		'Submitted': 'Submitted'
	},
	subjects: {
		email: 'Email',
		firstName: 'First Name',
		grade: 'Grade',
		lastName: 'Last Name',
		subject: 'Subject',
		subjectName: 'Subject Name',
		teacher: 'Teacher'
	},
	tabs: {
		alphaMasks: 'Alpha Masks',
		backgrounds: 'Backgrounds',
		bulkEditProjectApps: 'Edit Project Apps',
		clipArt: 'Clip Art',
		composerEvents: 'Composer Events',
		dashboard: 'Dashboard',
		globalAlphaMasks: 'Global Alpha Masks',
		globalBackgrounds: 'Global Backgrounds',
		globalClipArt: 'Global Clip Art',
		maskEditor: 'Mask Editor',
		projectTemplates: 'Project Templates',
		projectTemplate: 'Project Template',
		renderStatistics: 'Render Statistics',
		reports: 'Reports',
		returnLogin: 'Return Login',
		seasons: 'Seasons',
		seasonAddProjects: 'Add Projects To Season',
		signOut: 'Sign Out',
		studioDictionary: 'Studio Dictionary',
		"studio/Lab'SSettings": 'Studio/Lab Settings',
		subjectManagement: 'Subject Management',
		users: 'Users',
		whatsNew: 'What\'s New',
		workflows: 'Workflows',
		workerLogs: 'Worker Logs'
	},
	uploader: {
		choose: 'Choose',
		chooseFileMessage: 'Select as many %{imgFormats}, or pdf images as you want to upload (up to {{maxFileSize}} each)',
		chooseFileStepDescription: 'the Upload Photos button',
		click: 'Click',
		confirmUploadStepDescription: 'your desired photos',
		completeButton: 'Upload more photos',
		completeMessage: 'Your images have been successfully uploaded',
		done: 'Done',
		errors: {
			albumImportFinish: 'Failed to finish album import event',
			albumImportStart: 'Failed to create album import event',
			convertToJpg: 'Failed to convert file to jpg',
			download: 'Failed to download file to convert to jpg',
			empty: 'Image is an empty file with nothing in it',
			fileDetected: '%{type} file detected and is not supported',
			imageTooBig: 'Image is too big (max is %{maxFileSize})',
			loadRequirements: 'Failed to load requirements from PLIC.  Please refresh and try again.',
			minDimensions: 'Image is too small at %{width} x %{height} (needs to be a minumum of %{minWidth} x %{minHeight})',
			onePhoto: 'This upload requires exactly 1 photo',
			supportedType: 'Not a supported image type',
			uploadPLIC: 'Failed to create photo on PLIC',
			uploadS3: 'Failed to upload photo to S3',
			verifyPLIC: 'Failed to verify photo on PLIC'
		},
		failedUploadsMessage: '%{failedImages} out of %{allImages} images were uploaded. The images that failed to upload are listed below. Please check your internet connection and try again. If you continue having trouble, try connecting to a different network or try again later.',
		googleDrive: 'Upload from Google Drive™',
		googlePhotos: 'Upload from Google Photos™',
		imagesSelected: '%{images} image selected |||| %{images} images selected',
		instagram: 'Upload from Instagram™',
		mustSelectImage: 'You must select at least one jpg, png, or pdf image to upload',
		reason: 'Reason',
		rejectedFiles: 'Rejected files',
		startOver: 'Start Over',
		startUploading: 'Start Uploading',
		tags: 'Use photo tags to describe the image(s) you are uploading',
		uploadFinished: 'Uploads Finished',
		uploadFolder: 'Upload Folder',
		uploadPhotos: 'Upload Your Photos',
		uploadProgressStepDescription: 'patiently while your photos upload...',
		uploadRate: 'Uploading %{bitrate}',
		uploadStatus: 'Upload Status',
		wait: 'Wait'
	}
};