window.locales['en-CA'] = {
	composer: {
		tools: {
			alignCenter: 'Align Centre',
			alignHorizontalCenter: 'Align horizontal centre',
			alignVerticalCenter: 'Align vertical centre',
			backgroundColor: 'Background colour',
			fontColor: 'Font colour'
		}
	},
	misc: {
		center: 'Centre',
		color: 'Colour'
	}
};