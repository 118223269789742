/* eslint-disable */

$.getGETParams = function(decode, options) {
	options = $.extend({}, options);
	if(decode === undefined) {
		decode = false;
	}

	if($.cachedGETParams) {
		return $.cachedGETParams;
	}
	var queryDict = {};
	var location = options.location ? options.location : window.location;
	location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1];});
	for(var i in queryDict) {
		if(typeof queryDict[i] != 'undefined') {
			if(decode) {
				if(queryDict[i].lastIndexOf('%') == queryDict[i].length - 1) {
					queryDict[i] = queryDict[i].substr(0, queryDict[i].length - 1);
				}
				queryDict[i] = decodeURIComponent(queryDict[i]);
			}
		} else {
			// ?debug should be the same as ?debug=true
			queryDict[i] = true;
		}
	}
	$.cachedGETParams = queryDict;
	
	return queryDict;
};

function IsRecursiveError(stopSource) {
	var err = new Error();
	err.stopSource = stopSource;
	Object.setPrototypeOf(err, IsRecursiveError.prototype);

	return err;
}
IsRecursiveError.prototype = Object.create(
	Error.prototype,
	{name: {value: 'IsRecursiveError', enumerable: false}}
);

$.removeFunctions = function(source, ignoreProps, sourceStack) {
	if((sourceStack && sourceStack.length > 50) || !$.isInit(source)) {
		return null;
	} else if(!sourceStack) {
		sourceStack = [];
	}

	try {
		var target;
		if ($.isArray(source)) {
			target = [];

			for (var i in source) {
				if ($.isInit(source[i]) && typeof source[i] == 'object') {
					// Ignore basic recursion
					if (source !== source[i]) {
						target.push($.removeFunctions(source[i], ignoreProps, $.merge([source], sourceStack)));
					}
				} else if (typeof source[i] != 'function') {
					target.push(source[i]);
				}
			}
		} else {
			target = {};

			for (var i in source) {
				if(source[i] && source[i].nodeName || typeof source[i] == 'function' || (ignoreProps && ignoreProps.indexOf(source[i]) != -1)) {
					continue;
				} else if ($.isInit(source[i]) && typeof source[i] == 'object') {
					// Ignore basic recursion
					if(source === source[i] || sourceStack.indexOf(source[i]) != -1) {
						throw new IsRecursiveError(source[i]);
					} else {
						target[i] = $.removeFunctions(source[i], ignoreProps, $.merge([source], sourceStack));
					}
				} else {
					target[i] = source[i];
				}
			}
		}

		return target;
	} catch(e) {
		if(e instanceof IsRecursiveError && $.removeFunctions.caller == $.removeFunctions) {
			if(e.stopSource == source) {
				return null;
			} else {
				throw e;
			}
		} else {
			$.fireErrorReport(null, 'Failed $.removeFunction', 'Failed $.removeFunction', e);
		}
		return null;
	}
};

$.fn.center = function (corner) {
	var parent = $(this).parent();
	
	var heightDivide = 2;
	if(corner == 'top') {
		heightDivide = 4;
	}
	
	this.css('top', Math.max(0, ((parent.height() - $(this).outerHeight()) / heightDivide) +  parent.scrollTop()) + "px");
	this.css('left', Math.max(0, ((parent.width() - $(this).outerWidth()) / 2) +  parent.position().left) + "px");
	
	return this;
};

$.fn.fixScaleDifference = function(ratio) {
	try {
		if (ratio != $.PRODUCTION_RATIO && this.isAttached()) {
			// Get original height
			var elem = this[0];
			var origMarginTop = $(elem).getFloatStyle('margin-top');
			var origMarginBot = $(elem).getFloatStyle('margin-bottom');
			var originalHeight;
			if(origMarginBot || origMarginTop) {
				var origTop = $(this).getTopWithMargins();
				var origBot = $(this).getBotWithMargins();
				originalHeight = (origBot - origTop) / ratio;
			} else {
				originalHeight = elem.getBoundingClientRect().height / ratio;
			}

			// Get scaled height
			var playground = $('<div class="flowPage" style="visibility: hidden">').appendTo('body');
			// So we have the same text overflow
			if(!origMarginBot && elem.wrapper) {
				var pageWidth = elem.wrapper.container.getBoundingClientRect().width;
				$(playground).css('width', (pageWidth / ratio * $.PRODUCTION_RATIO) + 'px');
			}
			var header = $('<div>Header</div>').appendTo(playground);
			var footer = $('<div>Footer</div>').appendTo(playground);

			var html = elem.outerHTML;
			html = $.scaleText($.scaleText(html, $.FONT_MULTIPLIER / ratio), $.PRODUCTION_RATIO / $.FONT_MULTIPLIER);
			$(html).css({
				'margin-top': (origMarginTop / ratio * $.PRODUCTION_RATIO) + 'px',
				'margin-bottom': (origMarginBot / ratio * $.PRODUCTION_RATIO) + 'px',
				visibility: 'hidden',
				position: 'relative'
			}).insertBefore(footer);
			var productionHeight = (footer[0].getBoundingClientRect().top - header[0].getBoundingClientRect().bottom) / $.PRODUCTION_RATIO;
			playground.remove();

			var heightDiff = (productionHeight - originalHeight) * ratio;
			if(Math.abs(heightDiff) < 3) {
				if(origMarginBot > 0) {
					this.css({
						'margin-bottom': (origMarginBot + heightDiff)
					});
				} else {
					var computedStyle = window.getComputedStyle(elem);
					var borderWidth = parseFloat(computedStyle.borderLeftWidth.replace('px', ''));

					this.css({
						'height': (elem.getBoundingClientRect().height + heightDiff - borderWidth * 2) + 'px'
					});
				}
			}
		}
	} catch(e) {
		console.error('Failed to fix scale difference', e);
	}
};
$.fn.getTopWithMargins = function() {
	var elem = this[0];
	if(elem.previousElementSibling) {
		if(elem.previousElementSibling.style.display === 'none') {
			return $(elem.previousElementSibling).getTopWithMargins();
		} else {
			return elem.previousElementSibling.getBoundingClientRect().bottom;
		}
	} else {
		return elem.parentNode.getBoundingClientRect().top;
	}
};
$.fn.getBotWithMargins = function() {
	var elem = this[0];
	if(elem.nextElementSibling) {
		if(elem.nextElementSibling.style.display === 'none') {
			return $(elem.nextElementSibling).getBotWithMargins();
		} else {
			return elem.nextElementSibling.getBoundingClientRect().top;
		}
	} else {
		return elem.parentNode.getBoundingClientRect().bottom;
	}
};
$.scaleText = function(html, ratio, round) {
	// Loop through and replace every instead of font-size with ratio'd version
	var needle = 'font-size:';
	var index = -1;
	var replacements = [];

	// Setup all of the replacements as [before, after]
	while((index = html.indexOf(needle, index + 1)) != -1) {
		var startLength = index + needle.length;

		var isPx = false;
		var ptIndex = html.indexOf('pt', startLength);
		var pxIndex = html.indexOf('px', startLength);
		if(pxIndex != -1 && (pxIndex < ptIndex || ptIndex == -1)) {
			ptIndex = pxIndex;
			isPx = true;
		}

		var fontSize = html.substr(startLength, ptIndex - startLength);

		// Replace all non-numeric characters (ex: One case with 'NaN12pt')
		// Replace all periods after first so we don't get 123.123.123
		var fontSizeFloat = parseFloat(fontSize.replace(/[^\d.-]/g,'').replace(/(\d{3})\.(\d{3})/g, ''));
		if(!isNaN(fontSizeFloat)) {
			var scaledFont = fontSizeFloat * ratio;
			if (round && $.isWithinDiff(scaledFont, Math.round(scaledFont), 0.0001)) {
				scaledFont = Math.round(scaledFont);
			}

			// If we are seeing px somehow, convert to pt
			if(isPx) {
				fontSize += 'px';
				scaledFont = $.convertToPt(scaledFont) + 'pt';
			} else {
				fontSize += 'pt';
				scaledFont += 'pt';
			}

			replacements.push([needle + fontSize, needle + scaledFont]);
		} else {
			replacements.push([needle + fontSize + 'pt', '']);
		}
	}

	// Go through and do all replacements now that we have found everything
	for(var i = 0; i < replacements.length; i++) {
		html = html.replace(replacements[i][0], replacements[i][1]);
	}
	
	return html;
};
$.getLargestFontSize = function(html) {
	// Loop through and replace every instead of font-size with ratio'd version
	var needle = 'font-size:';
	var index = -1;
	var replacements = [];

	// Setup all of the replacements as [before, after]
	var largestFontSize = -1;
	while((index = html.indexOf(needle, index + 1)) != -1) {
		var startLength = index + needle.length;
		var fontSize = parseFloat(html.substr(startLength, html.indexOf('pt', startLength) - startLength));
		if(fontSize > largestFontSize) {
			largestFontSize = fontSize;
		}
	}

	return largestFontSize;
};
$.fn.getMaxDimension = function(dim, recursion) {
	if(!recursion) {
		// If we are just a simple div, try to check against text range dimensions
		if(this[0].childNodes.length === 1 && this[0].childNodes[0].nodeName.toLocaleLowerCase() == '#text') {
			var range = document.createRange();
			range.selectNodeContents(this[0]);
			var rect = range.getBoundingClientRect();

			return rect[dim];
		}

		recursion = 0;
	}

	var maxDim = this[0].getBoundingClientRect()[dim];
	this.children().each(function() {
		maxDim = Math.max(maxDim, $(this).getMaxDimension(dim, recursion + 1));
	});

	return maxDim;
};

$.fn.removeTrailingBrs = function() {
	var me = this[0];
	if(me.childNodes.length == 1) {
		// Go recursively so we can find trailing <br>s further down
		$(me.childNodes[0]).removeTrailingBrs();
	} else {
		// Cycle through and remove all empty <div><br></div> elements on the end
		for (var i = me.childNodes.length - 1; i >= 0; i--) {
			var child = me.childNodes[i];
			if ($(child).isRecursiveElem('br')) {
				$(child).remove();
			} else {
				return;
			}
		}
	}
};

$.setupViewFullResolutionButton = function(button, image, title, options) {
	if(!options) {
		options = {};
	}

	$(button).click(function () {
		$.viewFullResolutionImage(image, title, options);
	}).popup({
		content: 'Click to view ' + (options.download ? 'and download ' : '') + 'full resolution image'
	});
};
$.viewFullResolutionImage = function(images, titles, options) {
	if(!options) {
		options = {};
	}
	if(!$.isArray(images)) {
		images = [images];
		titles = [titles];
	}

	var imageSet = [];
	for(var i = 0; i < images.length; i++) {
		var image = images[i];
		imageSet.push({
			photo: image,
			href: $.getPlicThumbnail(image),
			title: '&nbsp;',
			delayedTitle: titles[i]
		});
	}

	if(options.startPhoto && imageSet.length > 1) {
		var index = images.indexOf(options.startPhoto);
		if(index > 0) {
			imageSet = $.merge($.merge([imageSet[index]], imageSet.slice(index + 1)), imageSet.slice(0, index));
		}
	}

	$.fancybox.open(imageSet, {
		padding: 0,
		type: 'image',
		afterShow: function () {
			// Do this way for xss reasons
			var titleDiv = this.skin.find('.fancybox-title .child');
			titleDiv.text(this.delayedTitle);
			if (options.download) {
				titleDiv.append(' <a href="' + $.getPlicDownloadUrl(this.photo) + '" download target="_blank">(Download)</a>');
			}

			var innerWrapper = this.skin.find('.fancybox-inner');
			innerWrapper.addClass('disableSaveAs');
		}
	});
};

$.getGuid = $.getUniqueId = (function() {
	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
	return function() {
		return s4() + s4() + '-' + s4() + '-4' + s4().slice(1) + '-8' + s4().slice(1) + '-' + s4() + s4() + s4();
	};
})();

$.waitFor = function(options) {
	if(options.test()) {
		options.onComplete();
	} else {
		window.setTimeout(function() {
			$.waitFor(options);
		}, options.interval ? options.interval : 100);
	}
};
$.waitForWorker = function(worker, options) {
	if(!worker) {
		throw 'No worker defined';
	}

	window.setTimeout(function() {
		$.proxyAjax({
			url: 'ajax/getWorkerStatus.php',
			data: {
				token: worker
			},
			dataType: 'json',
			type: 'POST',
			success: function(data) {
				if(data.status == 'invalid' || data.status == 'failed') {
					if(options.onError) {
						options.onError();
					}
				} else if(data.status == 'complete') {
					if(options.onComplete) {
						options.onComplete();
					}
				} else {
					$.waitForWorker(worker, options);
				}
			},
			error: function() {
				if(options.onError) {
					options.onError();
				}
			}
		});
	}, options.interval ? options.interval : 2000);
};
$.waitForWorkers = function(workers, options) {
	window.setTimeout(function() {
		$.proxyAjax({
			url: 'ajax/getWorkerStatus.php',
			data: {
				tokens: workers
			},
			dataType: 'json',
			type: 'POST',
			success: function(data) {
				if(data.status == 'invalid' || data.status == 'failed') {
					if(options.onError) {
						options.onError();
					}
				} else if(data.status == 'complete') {
					if(options.onComplete) {
						options.onComplete();
					}
				} else {
					$.waitForWorkers(workers, options);
				}
			},
			error: function() {
				if(options.onError) {
					options.onError();
				}
			}
		});
	}, options.interval ? options.interval : 2000);
};

$.fn.doesTextOverflow = function(checkHeight) {
	if(checkHeight === undefined) {
		checkHeight = true;
	}

	var el = this[0];

	var clientWidth = $(el).getFloatStyle('width') || el.clientWidth;
	var clientHeight = $(el).getFloatStyle('height') || el.clientHeight;

	var scrollWidth, scrollHeight;
	if(el.svgEditor) {
		scrollWidth = $(el.svgEditor).getFloatAttribute('width');
		scrollHeight = $(el.svgEditor).getFloatAttribute('height');
	} else {
		scrollWidth = el.scrollWidth;
		scrollHeight = el.scrollHeight;
	}

	return (clientWidth < scrollWidth && !$.isWithinDiff(clientWidth, scrollWidth, 1)) ||
		(checkHeight && clientHeight < scrollHeight && !$.isWithinDiff(clientHeight, scrollHeight, 1));
};
$.fn.doesTextOverflowMoreThan = function(lines) {
	return this.getNumberOfLines() > lines;
};
$.fn.getNumberOfLines = function() {
	var el = this[0];
	var computedStyle = window.getComputedStyle(el, null);
	var lineHeight = parseFloat(computedStyle.getPropertyValue('line-height').replace('px', ''));

	var startHeightStyle = el.style.height;
	el.style.height = '';
	var elHeight = el.scrollHeight;
	el.style.height = startHeightStyle;

	return Math.round(elHeight / lineHeight);
};

$.checkPlicUpInitialized = function(onComplete) {
	if($().PlicUp) {
		onComplete();
	} else {
		window.setTimeout(function() {
			$.checkPlicUpInitialized(onComplete);
		}, 500);
	}
};

$.isRecursive = function(depthToCheck, maxRecursion, useCallee) {
	if(!depthToCheck) {
		depthToCheck = 1;
	}
	if(!maxRecursion) {
		maxRecursion = 1;
	}

	var originCaller = arguments.callee.caller;
	var caller = originCaller.caller;
	var depth = 0;
	var recursion = 0;
	while(depth < depthToCheck && caller) {
		if(caller == originCaller) {
			recursion++;
			if(recursion >= maxRecursion) {
				return true;
			}
		}

		// This should only happen if passing across a strict function.  Ignore since we can't go any further back
		try {
			if(useCallee) {
				caller = caller.callee.caller;
			} else {
				caller = caller.caller;
			}
		} catch(e) {
			return false;
		}

		depth++;
	}

	return false;
};

$.isWithinDiff = function(val, anchor, allowedDiff) {
	return Math.abs(val - anchor) <= allowedDiff;
};
$.anchorWithinDiff = function(val, anchor, allowedDiff) {
	if($.isArray(anchor)) {
		for(var i = 0; i < anchor.length; i++) {
			val = $.anchorWithinDiff(val, anchor[i], allowedDiff);
		}

		return val;
	} else {
		if ($.isWithinDiff(val, anchor, allowedDiff)) {
			return anchor;
		} else {
			return val;
		}
	}
};

$.fn.getEditableSelection = function() {
	var div = this[0];
	var doc = div.ownerDocument, win = doc.defaultView;
	var range = win.getSelection().getRangeAt(0);
	var preSelectionRange = range.cloneRange();
	preSelectionRange.selectNodeContents(div);
	preSelectionRange.setEnd(range.startContainer, range.startOffset);
	start = preSelectionRange.toString().length;
	end = start + range.toString().length;

	return {
		start: start,
		end:  end
	}
};
$.fn.setEditableSelection = function(start, end) {
	var div = this[0];
	var doc = div.ownerDocument, win = doc.defaultView;
	var charIndex = 0, range = doc.createRange();
	range.setStart(div, 0);
	range.collapse(true);
	var nodeStack = [div], node, foundStart = false, stop = false;

	while (!stop && (node = nodeStack.pop())) {
		if (node.nodeType == 3) {
			var nextCharIndex = charIndex + node.length;
			if (!foundStart && start >= charIndex && start <= nextCharIndex) {
				range.setStart(node, start - charIndex);
				foundStart = true;
			}
			if (foundStart && end >= charIndex && end <= nextCharIndex) {
				range.setEnd(node, end - charIndex);
				stop = true;
			}
			charIndex = nextCharIndex;
		} else {
			var i = node.childNodes.length;
			while (i--) {
				nodeStack.push(node.childNodes[i]);
			}
		}
	}

	var sel = win.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
};

$(document).ajaxError(function(e, xhr, settings, thrownError) {
	try {
		var data = JSON.parse(xhr.responseText);
		var reason = data.reason;
		if(reason.indexOf('consecutive logins') != -1 && settings.url != 'ajax/switchUser.php') {
			$('.modals > .modal').remove();
			Alert('Error', 'Too many consecutive logins. Logout on another computer first, then try again. Contact PhotoLynx for additional users.', function() {
				window.location = '/login';
			});
		}
	} catch(e) {

	}
});

// TODO: Remove after completing migration to v2 client
$.setupBugsnagHandler = function() {
	Bugsnag.notifyReleaseStages = ['staging', 'production'/*, 'dev'*/];
	$.globalBugsnagInfo = {};
	Bugsnag.beforeNotify = function(payload, metadata) {
		if($.globalBugsnagInfo) {
			$.extend(metadata, $.globalBugsnagInfo);
		}

		if(payload.name == 'TypeError' && payload.message && !metadata.groupingHash) {
			metadata.groupingHash = payload.message;
		}
	};
	$.setupBugsnagAjaxHandler();
};
$.setupBugsnagAjaxHandler = function() {
	$(document).ajaxError(function(event, jqXHR, settings, errorThrown) {
		if(errorThrown == 'abort' || !jqXHR.responseText || jqXHR.responseText.indexOf('has already been taken') != -1 || jqXHR.responseText.indexOf('has been taken') != -1 || jqXHR.responseText.indexOf('Duplicate ') != -1 || $.disableAjaxErrorReporting || (!jqXHR.status && !errorThrown) || jqXHR.status == 503 || jqXHR.retryingError || jqXHR.responseText.indexOf('Not activated') != -1 || jqXHR.responseText.indexOf('Not logged in') != -1 || jqXHR.responseText.indexOf('consecutive logins') != -1
			|| settings.url.indexOf('.s3.') != -1 || jqXHR.status == 404 || jqXHR.status == 409 || jqXHR.responseText.indexOf('must be in between 1 Byte and') != -1 || jqXHR.responseText.indexOf('not allowed to use') != -1 || (jqXHR.responseText || '').toLowerCase().indexOf('site blocked') != -1) {
			return;
		}

		var loggedData = 'null';
		if(settings.data) {
			loggedData = settings.data.length < 4000 ? settings.data : ('large data: ' + settings.data.length);
		}

		var groupingHash = settings.url;
		if(jqXHR.status >= 500 && jqXHR.status < 600) {
			groupingHash = '500 errors';
		}

		if(Bugsnag.Client) {
			// V7 API
			Bugsnag.notify("AjaxError", "HTTP " + jqXHR.status + ": " + errorThrown, function(event, event2) {
				if(!event) {
					event = event2;
				}
				event.severity = 'warning';
				event.groupingHash = groupingHash;

				event.addMetadata('xhr', {
					status: jqXHR.status,
					error_thrown: errorThrown,
					response_text: jqXHR.responseText
				});

				event.addMetadata('ajax', {
					url: settings.url,
					type: settings.type,
					data: loggedData
				});
			});
		} else {
			// V2 API - can remove after transition to webpack complete
			Bugsnag.notify("AjaxError", "HTTP " + jqXHR.status + ": " + errorThrown, {
				xhr: {
					status: jqXHR.status,
					error_thrown: errorThrown,
					response_text: jqXHR.responseText
				},
				ajax: {
					url: settings.url,
					type: settings.type,
					data: loggedData
				},
				groupingHash: groupingHash
			}, 'warning');
		}
	});

	$(window).on('beforeunload', function() {
		$.disableAjaxErrorReporting = true;
		Bugsnag.notifyReleaseStages = [];
	});
};
$.fireErrorReport = function(userMessage, summary, details, debugDetails) {
	if(userMessage) {
		$.Alert('Error', userMessage);
	}
	console.error(details ? details : summary, debugDetails);
	if(window.Bugsnag) {
		try {
			var metadata = {
				groupingHash: summary
			};
			if(debugDetails) {
				metadata.details = debugDetails;
			}

			if(Bugsnag.Client) {
				// V7 API
				var reportedError = summary;
				if(debugDetails && debugDetails.exception) {
					reportedError = debugDetails.exception;
				}
				
				Bugsnag.notify(reportedError, function(event, event2) {
					if(!event) {
						event = event2;
					}

					event.severity = 'error';
					event.context = details;
					event.groupingHash = summary;

					if(debugDetails) {
						event.addMetadata('details', debugDetails);
					}
				});
			} else {
				// V2 API - can remove after transition to webpack complete
				if(metadata.details && metadata.details.exception) {
					var exception = metadata.details.exception;
					delete metadata.details.exception;

					Bugsnag.notifyException(exception, summary, metadata, 'error');
				} else {
					Bugsnag.notify(summary, details, metadata, 'error');
				}
			}
		} catch (e) {
			console.error(e);
		}
	}

	// In tests we want anything like this to be a hard error
	if(window.jasmine) {
		if(debugDetails) {
			if (debugDetails.exception) {
				throw debugDetails.exception;
			} else if(debugDetails.throwException) {
				throw debugDetails.throwException;
			}
		}
	}
};

$.displayBrowserWarning = function(afterReject) {
	$.reject({
		imagePath: 'css/images/',
		reject: {
			msie9: true,
			firefox3: true
		},
		display: ['firefox', 'chrome', 'msie'],
		browserInfo: {
			firefox: {
				text: 'Firefox',
				url: 'http://www.mozilla.com/firefox/'
			},
			chrome: {
				text: 'Chrome',
				url: 'http://www.google.com/chrome/'
			},
			msie: {
				text: 'Internet Explorer 10+',
				url: 'http://www.microsoft.com/windows/Internet-explorer/'
			}
		},
		close: false,
		afterReject: afterReject
	});
};
$.displayIEComposerWarning = function(options) {
	options = $.extend({}, options);
	// Hack so I can keep using FF without getting constant warnings
	if(!$.browser.chrome && (!$.browser.firefox || !options.allowFF) &&
		(!$.CurrentUser || $.CurrentUser.userId != 73) && !$.IsDevEnv) {
		if(!$('.modals:visible').length) {
			$.AlertWarning('We recommend using Google\'s Chrome browser for the most accurate and best experience when designing in ' + $.AppName + '. You can download Chrome from <a href="https://www.google.com/chrome/browser/desktop/" target="_blank">here</a>.', null, {
				allowMultiple: true,
				allowHtml: true
			});
		}
	}
};

$.setupAlertify = function(topElementSelector, position) {
	if(!position) {
		position = 'top left';
	}

	var topElement;
	if(topElementSelector) {
		topElement = $(topElementSelector);
	} else {
		topElement = $('body').children('.ui.pusher');
	}
	if(!topElement.length) {
		topElement = $('body');
	}
	alertify.parent(topElement[0]);
	alertify.closeLogOnClick(true).logPosition(position);
};

$.setSingleTimeout = function(id, onComplete, delay) {
	$.clearSingleTimeout.call(this, id);

	// Don't pass onComplete to just clear
	if(onComplete) {
		var me = this;
		this[id + '-onComplete'] = onComplete;
		this[id] = window.setTimeout(function() {
			onComplete.call(me);
			me[id] = null;
			me[id + '-onComplete'] = null;
		}, delay);
	}
};
$.flushSingleTimeout = function(id) {
	var onComplete = this[id + '-onComplete'];
	if(onComplete) {
		$.clearSingleTimeout.call(this, id);
		onComplete.call(this);
	}
};
$.clearSingleTimeout = function(id) {
	if(this[id]) {
		window.clearTimeout(this[id]);
		this[id] = null;
		this[id + '-onComplete'] = null;
	}
};

$.returnArray = function(val) {
	if($.isArray(val)) {
		return val;
	} else {
		return [val];
	}
};

$.getStudioSetting = function(name, defaultValue) {
	return $.getSettingFromSet($.studioSettings, name, defaultValue);
};
$.getProjectSetting = function(name, defaultValue) {
	return $.getSettingFromSet($.projectSettings, name, defaultValue);
};
$.getComposerMySetting = function(name, defaultValue) {
	let composerSettings = {};
	if($.userExtras && $.userExtras.composerSettings) {
		composerSettings = $.userExtras.composerSettings;
	}

	return $.getSettingFromSet(composerSettings, name, defaultValue);
}

$.getSettingFromSet = function(set, name, defaultValue) {
	var foundSetting = $.getSettingDefinitionFromSet(set, name);

	var foundValue;
	if(foundSetting && $.isInit(foundSetting.value)) {
		foundValue = foundSetting.value;
	} else if($.isInit(foundSetting)) {
		foundValue = foundSetting;
	} else {
		foundValue = defaultValue;
	}

	if(foundValue === 'true') {
		foundValue = true;
	} else if(foundValue === 'false') {
		foundValue = false;
	}

	return foundValue;
};
$.getSettingDefinitionFromSet = function(set, name) {
	var foundSetting;
	if(!set) {

	} else if($.isPlainObject(set)) {
		foundSetting = set[name];
	} else if($.isArray(set)) {
		for(var i = 0; i < set.length; i++) {
			var setting = set[i];
			if(setting.name == name) {
				foundSetting = setting;
			}
		}
	}

	if(foundSetting && typeof foundSetting.extras === 'string') {
		foundSetting = $.extend({}, foundSetting, {
			field: null
		});
		$.extend(foundSetting, JSON.parse(foundSetting.extras));
	}

	return foundSetting;
}

$.isProjectFeatureEnabled = function(name) {
	return $.plicLicenseFeatures && !!$.plicLicenseFeatures[name];
};

$.doesSupportDirectoryUpload = function() {
	var tmpInput = document.createElement('input')
	tmpInput.type = 'file'
	return typeof tmpInput.webkitdirectory != 'undefined'
};