import axios from 'axios';
const rax = require('retry-axios');

const DEFAULT_RETRIES = 5;
export const plicAxios = axios.create({
	baseURL: process.env.VUE_APP_PLIC_URL + 'api/',
	headers: {
		Accept: process.env.VUE_APP_PLIC_ACCEPT,
		'X-Plic-App': process.env.VUE_APP_PLIC_APP_SLUG
	}
});
plicAxios.defaults.raxConfig = {
	instance: plicAxios,
	httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'],
	retry: DEFAULT_RETRIES,
	shouldRetry
};
plicAxios.defaults.timeout = 15000;
rax.attach(plicAxios);
window.plicAxios = plicAxios;

export const plicV1Axios = axios.create({
	baseURL: process.env.VUE_APP_PLIC_URL + 'api/',
	headers: {
		Accept: 'application/vnd.plic.io.v1+json',
		'X-Plic-App': process.env.VUE_APP_PLIC_APP_SLUG
	}
});
plicV1Axios.defaults.raxConfig = {
	instance: plicV1Axios,
	retry: DEFAULT_RETRIES,
	shouldRetry
};
plicV1Axios.defaults.timeout = 15001;
rax.attach(plicV1Axios);
window.plicV1Axios = plicV1Axios;

export const client = axios.create({
	headers: {}
});
client.defaults.raxConfig = {
	instance: client,
	httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'],
	retry: DEFAULT_RETRIES,
	shouldRetry
};
client.defaults.timeout = 15002;
rax.attach(client);

export const s3Client = axios.create({
	headers: {}
});
s3Client.defaults.raxConfig = {
	instance: s3Client,
	httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH', 'POST'],
	retry: DEFAULT_RETRIES,
	noResponseRetries: 3,
	statusCodesToRetry: [[100, 199], [400, 499], [500, 599]]
};
rax.attach(s3Client);
window.s3Client = s3Client;

export const appClient = axios.create({
	baseURL: process.env.VUE_APP_DEFAULT_HOST,
	headers: {}
});
appClient.defaults.raxConfig = {
	instance: appClient,
	httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'PATCH'],
	retry: DEFAULT_RETRIES,
	shouldRetry
};
rax.attach(appClient);
appClient.defaults.timeout = 15003;
window.appClient = appClient;

function shouldRetry(error) {
	const cfg = rax.getConfig(error);
	if(cfg.currentRetryAttempt >= cfg.retry) {
		return false;
	}

	// TODO: We should rely on cfg.httpMethodsToRetry, but on the second call it returns an empty list
	if(error.request && error.request['BS~~M'] && ['GET', 'PATCH'].includes(error.request['BS~~M']) && (error.message?.includes('timeout of') || (error.request.status >= 500 && error.request.status <= 599))) {
		return true;
	}

	// Fallback to whatever the default behavior is
	return rax.shouldRetryRequest(error);
}

export function shouldRetryAll(error) {
	const cfg = rax.getConfig(error);
	if(cfg.currentRetryAttempt >= cfg.retry) {
		return false;
	}

	// TODO: This is only necessary because a bug in rax causes these second call and on to fail shouldRetryRequest
	if(error.message?.includes('timeout of') || (error.request.status >= 500 && error.request.status <= 599)) {
		return true;
	}

	// Fallback to whatever the default behavior is
	return rax.shouldRetryRequest(error);
}

export function logAxiosError(errorMessage, error, extras = {}) {
	if(error?.message?.includes('timeout of')) {
		console.warn('skipping 0ms timeout error: ', error);
		return;
	}

	if(window.bugsnagClient && error.response?.status) {
		window.bugsnagClient.notify(error, (event) => {
			let request = error.request || {};
			let requestResponse = {};
			for(let i in request) {
				if(typeof request[i] === 'string') {
					requestResponse[i] = request[i];
				}
			}

			event.errors[0].errorMessage = event.groupingHash = errorMessage;
			event.addMetadata('request', requestResponse);
			event.addMetadata('response', error.response);
			event.addMetadata('message', error.message);

			for(let id in extras) {
				event.addMetadata(id, extras[id]);
			}
		});
	} else if(window.bugsnagClient) {
		// Really a regular error - sometimes we just catch everything but still want this to be logged
		window.bugsnagClient.notify(error, (event) => {
			event.errors[0].errorMessage = event.groupingHash = errorMessage;
			event.addMetadata('message', error.message);
		});
	}
}

export function setupAxiosAuthToken(plicToken) {
	plicV1Axios.defaults.headers.Authorization = plicAxios.defaults.headers.Authorization = appClient.defaults.headers.Authorization = 'Bearer ' + plicToken;
}
window.setupAxiosAuthToken = setupAxiosAuthToken;